export const hostApi = process.env.VUE_APP_API_URL;
export const hostFront = process.env.VUE_APP_FRONT_URL;
export const casLogin = `${process.env.VUE_APP_SSO_HOST}/login`;
export const casLogout = `${process.env.VUE_APP_SSO_HOST}/logout`;

export default {
  api: {
    authenticate: {
      login: hostApi + '/api/token-auth/',
      cas_login: hostApi + '/api/cas-auth/',
      lark_login: hostApi + '/api/lark-auth/',
      refresh: hostApi + '/api/token-refresh/',
    },
    account: {
      me: hostApi + '/api/account/me/',
      avatar: hostApi + '/api/account/avatar/',
      statistics: hostApi + '/api/account/statistics/',
      task_statistics: hostApi + '/api/account/task/statistics/',
      logout: hostApi + '/api/account/logout/',
      login_with_google: hostApi + '/google-login/',
      cas_logout: hostApi + '/api/account/cas-logout/',
      approval_proxy_list: hostApi + '/api/account/proxies',
      approval_proxy_detail: hostApi + '/api/account/proxies/%s',
      contact_list: hostApi + '/api/account/contacts',
      contact_detail: hostApi + '/api/account/contacts/%s/',
    },
    workflow: {
      workflow_list: hostApi + '/api/workflow/workflows/',  // すべてのワークフロー
      workflow_detail: hostApi + '/api/workflow/workflows/%s/',
      workflow_node_list: hostApi + '/api/workflow/workflows/%s/nodes/',
      node_list: hostApi + '/api/workflow/nodes/',
      node_detail: hostApi + '/api/workflow/nodes/%s/',
      forms_choice: hostApi + '/api/workflow/forms/choice/',
      nodes_choice: hostApi + '/api/workflow/nodes/choice/',
    },
    task: {
      workflow_list: hostApi + '/api/task/workflows/',  // 有効のワークフロー
      workflow_detail: hostApi + '/api/task/workflows/%s/',
      workflow_form: hostApi + '/api/task/workflows/%s/form/',
      task_list: hostApi + '/api/task/tasks/',
      unresolved_list: hostApi + '/api/task/tasks/unresolved/',
      approval_list: hostApi + '/api/task/tasks/approval/',
      approval_finished: hostApi + '/api/task/tasks/approval-finished/',
      finished_list: hostApi + '/api/task/tasks/finished/',
      observer_list: hostApi + '/api/task/tasks/observer/',
      task_detail: hostApi + '/api/task/tasks/%s/',
      task_form: hostApi + '/api/task/tasks/%s/form/',
      task_nodes: hostApi + '/api/task/tasks/%s/nodes/',
      task_undo_info: hostApi + '/api/task/tasks/%s/undo-info/',
      approval_node: hostApi + '/api/task/tasks/%s/nodes/%s/approval/',
      undo_node: hostApi + '/api/task/tasks/%s/nodes/%s/undo/',
      undo_vacation: hostApi + '/api/task/tasks/undo/vacation/',
      business_days: hostApi + '/api/task/business-days/%s/%s/',
    },
    attendance: {
      attendance_details: hostApi + '/api/attendance/tasks/details/%s/',
      modify_details: hostApi + '/api/attendance/tasks/modify-details/%s/',
    },
    employee: {
      employee_detail: hostApi + '/api/employee/employees/%s/',
      employee_vacations_month: hostApi + '/api/employee/employees/%s/vacations/year/%s/month/%s/',
      residence_status: hostApi + '/api/employee/employees/%s/residence-status/',
      organization_list: hostApi + '/api/employee/organizations/',
      bank_account_list: hostApi + '/api/employee/bank-accounts/',
      bank_account_detail: hostApi + '/api/employee/bank-accounts/%s/',
      prev_month_commuting: hostApi + '/api/employee/prev-commuting/',
      notification_list: hostApi + '/api/employee/notifications/',
      notification_read: hostApi + '/api/employee/notifications/%s/read/',
      payment_slip: hostApi + '/api/employee/payment-slip/',
      withholding: hostApi + '/api/employee/withholding/',
      paid_vacation_status: hostApi + '/api/employee/paid-vacation/status/',
      paid_vacation_end_date: hostApi + '/api/employee/paid-vacation/end-date/',
      vacations: hostApi + '/api/employee/vacations/year/%s/',
      vacations_month: hostApi + '/api/employee/vacations/year/%s/month/%s/',
      vacations_all: hostApi + '/api/employee/vacations/',
      attendance_employees: hostApi + '/api/employee/employees/',
      attendance_list: hostApi + '/api/employee/attendance/%s/',
      attendance_download: hostApi + '/api/employee/attendance/download/%s/%s/',
    },
    master: {
      bank_choice: hostApi + '/api/master/banks/choice/',
      branch_choice: hostApi + '/api/master/banks/%s/branches/choice/',
      approver_choice: hostApi + '/api/master/approvers/choice/',
      attachment_download: hostApi + '/api/master/attachment/%s/download/',
      register_firebase_device: hostApi + '/api/master/firebase-devices/',
      notice_list: hostApi + '/api/master/notices/',
    },
    expense: {
      upload: hostApi + '/api/expense/upload/',
      upload_list: hostApi + '/api/expense/upload-history/',
      upload_detail: hostApi + '/api/expense/upload-history/%s/',
      upload_detail_payment_slip: hostApi + '/api/expense/upload-history/%s/payment-slip/',
      upload_detail_withholding: hostApi + '/api/expense/upload-history/%s/withholding/',
      upload_detail_resend: hostApi + '/api/expense/upload-history/%s/resend/',
      attendance_by_month: hostApi + '/api/expense/attendance/year/%s/month/%s/',
    },
    company: {
      company_info: hostApi + '/api/master/company/info',
      company_calendar: hostApi + '/api/master/company/calendar',
    },
    third_part: {
      search_station: 'http://127.0.0.1:5000/api/station/stations/?q=%s',
    },
    assets: {
      assets_lend: hostApi + '/api/assets/assets-type/',
    }
  },
  rowsPerPage: 10,
  fileSizeLimit: 2 * 1024 * 1024,  /* 2MB */
  vapidKey: "BCnFe70UC7nDOCXKE05alvkG91BT6JQcnjldhZncBzVDtPnZAOpnHOXfzPjefBBBFLZl5Egb4YSqRNIoIZx4weA",
};
